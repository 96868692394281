<template>
  <TermLayout
    :tabDataList="tabDataList" />
</template>

<script>
import TermLayout from '@/views/terms/view/components/TermLayout'

export default {
  name: 'IdentityVerifyTerms',
  components:{
    TermLayout
  },
  data(){
    return{
      tabDataList:[
        {
          id:'SKT',
          text:'SKT',
          termId:'IdentityVerifyTermsSKT'
        },
        {
          id:'KT',
          text:'KT',
          termId:'IdentityVerifyTermsKT'
        },
        {
          id:'LGU',
          text:'LGU+',
          termId:'IdentityVerifyTermsLGU'
        }
      ],
    }
  },
}
</script>